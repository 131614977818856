<script lang="ts" setup>
const businessStore = useBusinessStore()

onMounted(() => {
  void businessStore.getStripeCustomerPortalUrl()
})

const stripeCustomerPortalUrl = computed(() => {
  return businessStore.stripeCustomerPortalUrl || ''
})

const isClosed = ref(false)
const close = () => {
  isClosed.value = true
}
</script>

<template>
  <div v-if="!isClosed && stripeCustomerPortalUrl" class="banner-payment-failed">
    <v-banner lines="two" theme="primary" class="banner-payment-failed__banner | pr-10">
      <v-icon color="c-danger">custom:warning</v-icon>

      <span name="BannerPaymentFailed" class="banner-payment-failed__text | text-body-2 text-grey-darker">
        {{ $t('banner.payment_failed.general.text') }}
        <span class="banner-payment-failed__text--link | font-weight-bold text-grey-darker"
          ><a :href="stripeCustomerPortalUrl" target="_blank" rel="noopener" class="text-grey-darker">{{
            $t('banner.payment_failed.general.link_text')
          }}</a></span
        >
      </span>

      <v-icon class="banner-payment-failed__close-btn" color="black-lighten-5" @click="close">custom:c_close</v-icon>
    </v-banner>
  </div>
</template>

<style lang="sass" scoped>
.banner-payment-failed
  z-index: 1000
  position: fixed
  background-color: $c-danger-bg
  top: 0
  left: 0
  right: 0
  text-align: center
  justify-content: center
  align-items: center

  .v-icon
    color: $c-danger

  &:deep(.v-banner__wrapper)
    border-bottom: none !important

  &__close-btn
    position: absolute
    right: 1.5rem
    top: 1.1rem
</style>
